@import "./_config";

.tradeControllerLoaderCont {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 240px 0;
  .tradeControllerLoaderText {
    margin-left: 16px;
    margin-bottom: 0;
  }
}

.tradeUpCont {
  display: flex;
  justify-content: center;
  width: 100%;
  .tradeSelectionCont {
    padding: 64px 16px 0;
    min-height: 680px;
    margin: 0;
    width: 100%;
    .descColumnTitleCont {
      margin-top: 112px;
      padding: 0 24px;
      align-self: flex-start;
      max-width: 900px;
      .generateTradeBtnCont {
        display: flex;
        flex-direction: column;
        align-items: center;
        .generateTradeBtn {
          margin: 32px 0 16px;
        }
        .tradeAvailabilityDisclaimer {
          font-size: 0.7rem;
        }
      }
      .statTrakBtn {
        pointer-events: none; // disable button actions while the platform only supports stattrak
      }

      /* saved trades btn - deprecated

      .savedTradeBtnCont {
        display: flex;
        align-self: center;
        column-gap: 16px;
        margin-top: 24px;
        .savedTradesBtn {
          width: 160px;
        }
        .savedTradeBtnSpacing {
          width: 100px;
        }
      }
        */
    }
    .tradeSummariesColumnCont {
      .tradeCollectionSelectors {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 0 24px;
        margin-top: 24px;
        .tradeCollectionSelectorCont {
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          align-items: flex-start;
          width: 100%;
          .tradeDropdownSelectorsLabel {
            margin: 0 0 4px;
          }
          .tradeCollectionSelectorDropdowns {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            column-gap: 16px;
          }
        }
        .tradeCollectionsAddtlFilters {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          width: 100%;
          .tradeCollectionFloatAdjustments {
            margin-right: 16px;
          }
          .tradeCollectionsAddtlFiltersCol {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: 360px;
            margin-top: 16px;
            .tradeDropdownSectionLabel {
              font-size: 1.2rem;
              margin-bottom: 4px;
            }
            .tradeCollectionAmountBreakdown {
              display: flex;
              flex-direction: row;
              column-gap: 16px;
              .tradeCollectionAmount {
                display: flex;
                flex-direction: column;
                .tradeCollectionAmountLabel {
                  display: flex;
                  align-items: center;
                  text-align: left;
                  svg {
                    height: 12px;
                    width: 12px;
                    margin-left: 4px;
                  }
                }
                .tradeCollectionAmountInput {
                  @include formInputs;
                  padding-left: 8px;
                }
              }
            }
          }
        }
      }
    }
  }
}
.modalCont {
  .modal-dialog {
    .modal-content {
      color: $light;
      background-color: $tu-dark-purple;
      .modal-body {
        text-align: center;
        margin: 48px;
        padding: 24px;
        h3 {
          margin-bottom: 32px;
        }
        .btn {
          min-width: 240px;
        }
      }
    }
  }
}

/* Trade summary styles */
.tradeCollectionAccordion,
.profileCollectionAccordion {
  margin: 24px 24px 0;
  .tradeSummaryCont {
    width: 100%;
    background-color: $tu-dark-purple;
    .tradeSummaryHeader .accordion-button {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      color: $light;
      background-color: $tu-dark-purple;
      padding: 20px 24px;
      &:not(.collapsed) .tradeSummaryHeaderItemTitle {
        color: $highlight;
      }
      &::after {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23c0c0c0' stroke='%23c0c0c0' stroke-width='3'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
      }
      .tradeSummaryHeaderItem {
        width: 100%;
        .tradeSummaryHeaderItemTitle {
          font-weight: bold;
        }
        p {
          text-align: center;
          margin: 0;
        }
        &.tradeSummaryHeaderDeleteIcon {
          max-width: 30px;
          margin-right: 8px;
          text-align: right;
        }
      }
    }
    .tradeSummaryAllTrades {
      padding: 16px auto;
      // max-width: 960px; // ensure a 5 column layout
      .tradeSkinsTitle {
        margin: 0 0 24px;
        &.tradesSkinOutcomesTitle {
          color: $light;
          padding: 8px 0;
          margin-top: 32px;
          background: $highlight-gradient;
          border-radius: 4px;
        }
      }
      .tradeSkinsList {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
        row-gap: 16px;
        column-gap: 4px;
        margin-bottom: 24px;
        .tradeSummarySkin a {
          text-decoration: none;
          &:hover {
            color: #ffffff;
          }
        }
      }
      .savedButtonIcon {
        margin-left: 4px;
      }
    }
  }
}

.tradeCollectionAccordion,
.tradeCollectionAccordionSample {
  &.tuAccordionWrapper {
    justify-content: flex-start;
    .tradeSummaryCont {
      max-width: 100%;
      .tradeSummaryAllTrades {
        padding: 16px;
        .tradeSkinsList {
          width: 100%;
          column-gap: 4px;
          grid-template-columns: repeat(5, 1fr);
        }
      }
    }
  }
}

/* Preview page styles - deprecated 
.tradeUpPreviewCont {
  display: flex;
  flex-direction: column;
  align-items: center;
  .tradeUpTitleCont {
    margin-top: 128px;
    padding-top: 24px;
    text-align: left;
    height: 400px;
    max-width: 100%;
    background-image: url("https://cdk-hnb659fds-assets-851725294463-eu-north-1.s3.eu-north-1.amazonaws.com/TUF_images/Section_BG.jpg");
    background-size: cover;
    background-repeat: no-repeat;
  }
  .tradeSelectionCont {
    justify-content: center;
    min-height: 400px;
    .tradeSummariesColumnCont
      .tradeCollectionSelectors
      .tradeCollectionSelectorCont
      .tradeDropdownSelectorsLabel {
      text-align: left;
      margin-bottom: 24px;
    }
    .tradeDropdownTryIt {
      text-align: left;
      margin-top: 16px;
      margin-left: 24px;
    }
  }
  .tradePreviewSubscribeBtnCont {
    margin-top: 24px;
  }

  .homepageCont {
    .homeDescriptionSection {
      margin-top: 0;
      padding: 96px 0 0;
      .homeDescTitle {
        margin-bottom: 32px;
      }
      &.tradeUpPreviewBenefits {
        padding-top: 16px;
      }
    }
    .homeFAQSection {
      padding-bottom: 32px;
    }
  }
}

.tradeCollectionSelectionBtn {
  width: 160px;
  height: 38px;
}

*/
