@import "./_config";

.authFormCont {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .authFormHeader {
    margin-bottom: 24px;
  }
  .authForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 640px;
    .authInputGroup {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      width: 100%;
      min-height: 100px;
      .form-label {
        text-align: left;
      }
    }
    .authSubmit {
      width: 240px;
    }
  }
  .authInputField {
    @include formInputs;
    width: 320px;
  }

  .loginPageAddtlOptions {
    margin-top: 16px;
    .loginPageRedirectLink {
      cursor: pointer;
      text-decoration: underline;
    }
  }
}

.modalCont {
  .resetModalContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 24px 0;
    .resetModalEmailSubmitGroup {
      margin-bottom: 16px;
      .authInputField {
        @include formInputs;
        width: 320px;
      }
    }
    .resetModalEmailBtn {
      width: 240px;
    }
  }
}
