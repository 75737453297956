@import "./_config";

// General styles
.skinCont,
.skinImgCard {
  width: 160px;
  height: 250px;
  background-color: $tu-purple;
  border-radius: 4px;
  box-shadow: -3px 3px 8 + px scale-color($tu-purple, $lightness: 10%);
  &:hover {
    box-shadow: -3px 3px 8 + px scale-color($tu-purple, $lightness: 30%);
  }
  &.skinTopResult {
    border: 1px solid #f5a623;
    box-shadow: -3px 3px 4 + px scale-color(#f5a623, $lightness: 10%);
    &:hover {
      box-shadow: -3px 3px 4 + px scale-color(#f5a623, $lightness: 30%);
    }
  }
  .skinCardHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    padding: 4px;
    .skinCardHeaderCollection {
      width: 100px;
      text-align: left;
      padding: 0;
      margin: 0;
      word-break: break-all;
      white-space: nowrap;
      overflow: hidden;
    }
    .skinCardFloatInput {
      width: 60px;
      text-align: right;
      color: $highlight;
      background-color: $tu-purple;
      border: none;
      cursor: text;
      &:focus-visible {
        outline: 1px solid $light;
        border-radius: 4;
      }
    }
    .skinCardSaticFloatDisplay {
      width: 55px;
      text-align: right;
      color: $highlight;
      margin: 0;
    }
  }
  .skinCardImg {
    min-height: 80px;
    max-width: 160px;
  }
  .skinCardTitle {
    margin-bottom: 4px;
    font-size: 11px;
    max-height: 18px;
  }
  .skinSelectedValues {
    font-size: 12px;
    color: $neon-turquoise;
    margin-top: 4px;
  }
  .skinCardPriceInput {
    color: $neon-turquoise;
    background-color: $tu-purple;
    text-align: center;
    border: none;
    cursor: text;
  }
}

/* Skin selector styles - deprecated
.selectorEl {
  height: 266px;
  .skinSelectedEditMenu {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    height: 16px;
    font-size: 16px;
    padding-left: 4px;
    svg {
      margin-top: -4px;
      margin-right: 8px;
      cursor: pointer;
    }
  }
  .skinSelectedCont {
    width: 100%;
  }
  .selectSkinCont {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    .selectSkinIcon {
      color: $highlight;
    }
  }
}
  */
