@import "../_config";

.navBarCont.navbar {
  min-height: 60px;
  padding: 0 32px;
  background-color: $tu-dark-purple;
  .navbar-brand,
  .nav-link {
    color: $light;
    cursor: pointer;
  }
  .navbar-brand {
    &:hover,
    &:focus {
      color: inherit;
    }
  }

  .menuLinks {
    height: 100%;
    justify-content: flex-end;
    .navbar-nav {
      align-items: center;
      padding-bottom: 0;
      .nav-link {
        margin-left: 16px;
        &:hover,
        &.active {
          color: $light;
        }
      }

      .headerSignupBtn {
        margin: 0 16px 8px;
        padding: 4px 16px;
        min-width: 90px;
      }

      .userDetailsBtn {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 8px 10px;
        border-radius: 50%;
        max-height: 40px;
      }
    }
  }

  .proTextCont {
    height: 40px;
    display: flex;
    align-items: flex-start;
    margin-left: -6px;
    .proText {
      height: 40px;
      font-size: 0.7em;
      font-weight: bold;
      color: $highlight;
    }
  }
}
.navbar-toggler-icon {
  border-radius: 4px;
  background-color: $tu-dark-purple;
}
.navbar-collapse .navbar-nav {
  padding: 0 0 16px;
  a,
  .proTextCont,
  .headerSignupBtn {
    margin-bottom: 8px;
  }
}
