@import "./styles/_config";

.App {
  height: 100%;
  text-align: center;

  .siteBG {
    min-height: 100vh;
    min-width: 100%;
    background-color: $tu-dark-purple;
  }

  .siteCont {
    height: 100%;
    width: 100%;
    padding: 24px 0;
  }
}
