@import "../_config";

.footerCont {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 96px;
  border-top: 2px solid $tu-purple;
  .footerRow {
    padding: 48px 0 32px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    width: 60%;
    justify-items: stretch;
    .footerLinkSection {
      display: flex;
      flex-direction: column;
      align-items: center;
      .footerLinkHeader {
        font-weight: bold;
      }
      .footerLink {
        margin: 0;
        text-align: left;
        cursor: pointer;
        text-decoration: none;
        width: 120px;
        &:hover {
          color: #ffffff;
        }
      }
      .footerSocialIcons {
        display: flex;
        justify-content: center;
        column-gap: 16px;
      }
    }
  }
}
