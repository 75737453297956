/* 1. **Primary Colors**:
- Neon Blue: #00DFFC (RGB: 0, 223, 252)
- Neon Pink: #FC00FF (RGB: 252, 0, 255)
- Deep Black Background: #0A0A0A (RGB: 10, 10, 10)

2. **Secondary Colors**:
- Light Blue: #5FDDFF (RGB: 95, 221, 255) for highlights and gradients
- Light Pink: #FF5FED (RGB: 255, 95, 237) for highlights and gradients
- Dark Grey: #313131 (RGB: 49, 49, 49) for secondary backgrounds and UI elements

3. **Accent Colors**:
- Metallic Silver: #C0C0C0 (RGB: 192, 192, 192) for icons and secondary graphics
- Electric Violet: #8A2BE2
*/

/* Static values */
$tu-dark-purple: #0a011e;
$tu-purple: scale-color(
  $color: $tu-dark-purple,
  $lightness: 10%,
);
$light: #c0c0c0;
$highlight: #8a2be2;
$neon-turquoise: #00dffc;
$highlight-gradient: linear-gradient(
  45deg,
  rgba(138, 43, 226, 1) 4%,
  rgba(10, 1, 30, 1) 96%
);

/* Font import */
@import url("https://fonts.googleapis.com/css?family=DM+Sans");

/* Theme overwrites */
$primary: $light;
$secondary: $highlight;
$dark: $tu-dark-purple;
$body-color: $light;
$body-secondary-color: $light;
$font-family-sans-serif: "DM Sans", sans-serif;

@import "bootstrap/scss/bootstrap";

/* Global styles */
h1,
h2 {
  color: #ffffff;
  font-weight: 700;
}

.loadingCont {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  height: 100%;
}

.collectionsDropdownCont {
  min-width: 160px;
  max-height: 38px;
  .dropdown-toggle {
    width: 100%;
    &.btn.show {
      &:hover {
        background-color: $highlight;
      }
    }
  }
  .dropdown-header {
    padding: 8px;
    .dropdownSearchField {
      width: 160px;
      background-color: $light;
      color: $tu-dark-purple;
      border-radius: 4px;
      border: none;
      &::placeholder {
        color: $tu-dark-purple;
      }
    }
  }
  .dropdown-menu.show {
    background-color: $tu-purple;
    overflow-y: scroll;
    max-height: 360px;
    min-width: 216px;
    padding: 2px 0;
    .dropdown-item {
      &.active {
        color: $light;
        background-color: $highlight;
      }
      &:hover {
        background-color: scale-color($color: $highlight, $lightness: 10%);
      }
    }
  }
}

.tuAccordionWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .tradeSummaryCont {
    max-width: 960px;
    .tradeSkinsList {
      justify-items: center;
    }
  }
}

/* User details popoer styles */
.userDetailsMenu {
  color: $light;
  background-color: $tu-purple;
  p {
    color: $highlight;
    margin-bottom: 4px;
  }
  .userDetailsMenuBody {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .userDetailsMenuItem {
      cursor: pointer;
      font-weight: bold;
      text-align: center;
    }
    .userLogoutBtn {
      text-align: center;
      text-decoration: underline;
      cursor: pointer;
      justify-self: flex-end;
      margin-top: 10px;
    }
  }
}

@mixin formInputs {
  width: 160px;
  height: 36px;
  background-color: $light;
  color: $tu-dark-purple;
  border-radius: 4px;
  border: none;
  font-size: 1rem;
  &.form-control:focus {
    color: $tu-dark-purple;
  }
  &::placeholder {
    color: $tu-dark-purple;
  }
}
