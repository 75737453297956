@import "./_config";

.conditionScaleCont {
  .scaleConditions {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 140px;
    margin: 0 10px;

    .scaleConditionsBtnGroup {
      display: flex;
      flex-direction: row;
      margin: 2px 10px;
      width: 140px;
      .scaleSection {
        width: 30px;
        height: 20px;
        padding: 0;
        font-size: 0.6rem;
        font-weight: bold;
        color: $light;
        background-color: transparent;
        border-color: $neon-turquoise;
      }

      .selectedConditionBg {
        background: repeating-linear-gradient(
          45deg,
          rgba(138, 43, 226, 0.7),
          rgba(138, 43, 226, 0.7) 3px,
          transparent 3px,
          transparent 6px
        );
      }

      .conditionScaleLine {
        content: "";
        position: absolute;
        top: 0;
        bottom: 20px; // Adjust to create space for text
        min-height: 20px;
        min-width: 2px;
      }

      // set line colors
      .currentValLine {
        background-color: $neon-turquoise;
      }
      /* Deprecated labels
      .maxLine {
        background-color: $light;
      }
      .minLine {
        background-color: $highlight;
      }
      */
    }

    .rangeText {
      display: flex;
      margin: 0 10px;
      width: 160px;

      /* Deprecated labels
      .minMaxlabelStyle {
        font-size: 8px;
        position: relative;
        color: $light;
      }

      .minLabel {
        color: $light;
        transform: translateX(calc(-100% + 5px));
      }
      .maxLabelColor {
        color: $highlight;
        transform: translateX(5px);
      }
        */

      .valueLabels {
        display: flex;
        min-height: 18px;
        width: 160px;
        .valueLabelStyle {
          display: flex;
          flex-direction: column;
          color: $highlight;
          font-size: 8px;
          line-height: 9px;
          position: relative;
          top: 8px;
          width: 30px;
        }

        /* Deprecated labels
        .minLabelColor {
          color: $light;
        }

        .maxLabelColor {
          transform: translateX(-5px);
        }

        // account for width of previous labels in JSX when correcting for location
        // the max value element comes first and is 30px wide, the min value element offsets this, and the value element offsets both of these previous labels
        .minValueLabel {
          transform: translateX(-35px);
        }
        */

        .currentValLabel {
          top: 0;
          color: $neon-turquoise;
          transform: translateX(-5px);
        }
      }
      .conditionLabels {
        display: none;
      }

      /* toggle labels on hover - deprecated labels
      &:hover .valueLabelStyle {
        display: none;
      }
      &:hover .conditionLabels {
        display: flex;
        justify-content: space-between;
        cursor: default;
        min-height: 18px;
        min-width: 140px;
        margin: 0 10px;
        font-size: 8px;
        position: relative;
        text-align: center;
        & span:not(:last-child) {
          transform: translateX(-50%);
        }
      }
      */
    }
  }
}

.staticConditionScale {
  .scaleConditions {
    .rangeText {
      /* Deprecated labels
      .maxLabelColor {
        color: $highlight;
        transform: translateX(calc(50% - 5px));
      }
      .minLabel {
        color: $light;
        transform: translateX(calc(-100% + 5px));
      }
      */
      .valueLabels {
        .maxLabelColor {
          color: $highlight;
          transform: translateX(-5px);
        }
      }

      &:hover .conditionLabels {
        min-height: 10px;
        max-height: 10px;
      }
    }
  }
}
