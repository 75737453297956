@import "./_config";

.homepageCont {
  display: flex;
  flex-direction: column;
  .homeHeroSection {
    position: relative;
    height: 100vh;
    max-height: 720px;
    width: 100%;
    display: grid;
    grid-template-columns: 60% 40%;
    place-items: center;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: url("https://cdk-hnb659fds-assets-851725294463-eu-north-1.s3.eu-north-1.amazonaws.com/TUF_images/Hero_BG.jpg");
      background-size: auto;
      background-repeat: no-repeat;
      background-position: center;
      opacity: 0.7;
      z-index: 1;
    }
    .homeTitleCont {
      position: relative;
      z-index: 2;
      display: flex;
      flex-direction: column;
      text-align: left;
      max-width: 80%;
      h1 {
        font-size: 3rem;
        margin-bottom: 12px;
      }
      h2 {
        font-size: 2rem;
      }
      .homeCTA {
        margin-top: 36px;
      }
    }
    .homeImageCont {
      display: flex;
      flex-direction: column;
      row-gap: 80px;
    }
  }
  .homeDescriptionSection {
    display: flex;
    flex-direction: column;
    margin-top: -10px;
    padding: 96px 0;
    .homeDescTitle {
      margin-bottom: 96px;
    }
    .homeDescSteps {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      .homeDescStep {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        max-width: 360px;
        .homeDescIcon {
          height: 80px;
          margin-bottom: 16px;
        }
      }
    }
  }
  .homeAboutSection {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 0 24px;
    text-align: left;
    margin-bottom: -30px;
    .homeAboutText {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 36px;
      .homeAboutTextTitle {
        font-weight: bold;
      }
    }
    .homeAboutImg {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      img {
        max-height: 440px;
      }
    }
  }
  .homeCTASection {
    padding: 128px 0;
    margin-top: 48px;
    width: 100%;
    background-image: url("https://cdk-hnb659fds-assets-851725294463-eu-north-1.s3.eu-north-1.amazonaws.com/TUF_images/Section_BG.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    .homeCTATitle {
      font-weight: bold;
    }
    .homeCTABtn {
      margin-top: 36px;
    }
  }
  .homeFAQSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 96px 0;
    .homeFAQAccordion {
      width: 100%;
      margin-top: 32px;
      .homeFAQItem {
        background-color: $tu-purple;
        width: 80%;
        .homeFAQQuestion .accordion-button {
          display: flex;
          color: $light;
          background-color: $tu-dark-purple;
          font-size: 1.2rem;
          font-weight: bold;
          padding: 24px 20px;
          &:not(.collapsed) .tradeSummaryHeaderItemTitle {
            color: $highlight;
          }
        }
        .homeFAQAnswer {
          text-align: left;
          padding: 24px 20px;
        }
      }
    }
  }
  .homeTestimonialSection {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
    .homeTestimonial {
      padding: 24px;
      max-width: 640px;
      .homeTestimonialText {
        display: flex;
        text-align: left;
        .homeTestimonialIcon {
          height: 80px;
          margin-right: 16px;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .homepageCont {
    .homeHeroSection,
    .homeAboutSection {
      grid-template-columns: 1fr;
    }
    .homeDescSteps {
      flex-wrap: wrap;
    }
    .homeAboutSection {
      grid-template-columns: 1fr;
      .homeAboutImg {
        grid-row: 1;
      }
    }
  }
}

/* Shared styles on calculator and tradeup pages */
.descColumnTitleCont {
  display: flex;
  flex-direction: column;
  align-self: center;
  padding: 16px 16px;
  p {
    text-align: left;
  }
  .rarityFilterToggles {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    column-gap: 16px;
    margin-top: 24px;
    .raritySelectorCont {
      display: flex;
      flex-direction: column;
      .raritySelectorLabel {
        text-align: left;
        margin: 0;
        max-width: 160px;
      }
      .rarityDropdownCont {
        .dropdown-toggle {
          min-width: 160px;
          max-height: 38px;
          &.btn.show {
            &:hover {
              background-color: $highlight;
            }
          }
        }
        .dropdown-menu.show {
          background-color: $tu-purple;
          .dropdown-item {
            &#dropdownItemCG {
              color: $tu-dark-purple;
              background-color: #afafaf;
              &:hover,
              &.active {
                background-color: scale-color(
                  $color: #afafaf,
                  $lightness: -10%
                );
              }
            }
            &#dropdownItemIG {
              color: $tu-dark-purple;
              background-color: #6496e1;
              &:hover,
              &.active {
                background-color: scale-color(
                  $color: #6496e1,
                  $lightness: -10%
                );
              }
            }
            &#dropdownItemMS {
              color: $tu-dark-purple;
              background-color: #4b69cd;
              &:hover,
              &.active {
                background-color: scale-color($color: #4b69cd, $lightness: 10%);
              }
            }
            &#dropdownItemR {
              color: $tu-dark-purple;
              background-color: #8847ff;
              &:hover,
              &.active {
                background-color: scale-color(
                  $color: #8847ff,
                  $lightness: -10%
                );
              }
            }
            &#dropdownItemC {
              color: $tu-dark-purple;
              background-color: #d32ce6;
              &:hover,
              &.active {
                background-color: scale-color(
                  $color: #d32ce6,
                  $lightness: -10%
                );
              }
            }
          }
        }
      }
    }
    .statTrakBtn {
      max-height: 38px;
    }
    .statTrakBtnActive {
      background-color: $highlight;
    }
  }
}

/* Legal styles */
.legalCont {
  margin: 96px;
  text-align: left;
  .legalHeaderCentered {
    text-align: center;
  }
}
